
export const GET_PCVGV_BODY_TYPE = "GET_PCVGV_BODY_TYPE"
export const GET_BODY_TYPE_LIST = "GET_BODY_TYPE_LIST"
export const GET_ALL_GCVPCV_MAKE = "GET_ALL_GCVPCV_MAKE"
export const GET_ALL_GCVPCV_MODEL = "GET_ALL_GCVPCV_MODEL"
export const GET_ALL_GCVPCV_FUEL = "GET_ALL_GCVPCV_FUEL"
export const GET_ALL_GCVPCV_VARINAT = "GET_ALL_GCVPCV_VARINAT"
export const GET_ALL_GCVPCV_RTO = "GET_ALL_GCVPCV_RTO"
export const GET_GCV_PCV_LEADS = "GET_GCV_PCV_LEADS"
export const STORED_PCV_JOURNEY = "STORED_PCV_JOURNEY"
export const RESET_PCV_JOURNEY = "RESET_PCV_JOURNEY"
export const STORE_ALLQUOTES_REQUEST = "STORE_ALLQUOTES_REQUEST"
export const GET_QUOTES_RESPONSE = "GET_QUOTES_RESPONSE"
export const GET_ACTIVE_INURER = "GET_ACTIVE_INURER"
