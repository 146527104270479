import * as types from './Types'

const initialState = {
    GCVPCVBodyType: [],
    GCVPCVBodyList: [],
    GCVPCVMake: [],
    GCVPCVModel: [],
    GCVPCVFuel: [],
    GCVPCVVeriant: [],
    GCVPCVRto: [],
    ActiveInsurer: [],
    PcvJourney: {},
    PCVLeadInqId: {},
    AllQuetReq: {},
    loading: true
}

export const GcvPcvReducers = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case types.GET_PCVGV_BODY_TYPE:
            return {
                ...state,
                GCVPCVBodyType: payload,
                loading: false
            }
        case types.GET_BODY_TYPE_LIST:
            return {
                ...state,
                GCVPCVBodyList: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_MAKE:
            return {
                ...state,
                GCVPCVMake: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_MODEL:
            return {
                ...state,
                GCVPCVModel: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_FUEL:
            return {
                ...state,
                GCVPCVFuel: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_VARINAT:
            return {
                ...state,
                GCVPCVVeriant: payload,
                loading: false
            }
        case types.GET_ALL_GCVPCV_RTO:
            return {
                ...state,
                GCVPCVRto: payload,
                loading: false
            }
        case types.STORED_PCV_JOURNEY:
            return {
                ...state,
                PcvJourney: payload,
                loading: false
            }
        case types.GET_GCV_PCV_LEADS:
            return {
                ...state,
                PCVLeadInqId: payload,
                loading: false
            }
        case types.GET_ACTIVE_INURER:
            return {
                ...state,
                ActiveInsurer: payload,
                loading: false
            }
        case types.STORE_ALLQUOTES_REQUEST:
            return {
                ...state,
                AllQuetReq: payload,
                loading: false
            }
        case types.RESET_PCV_JOURNEY:
            return {
                ...state,
                GCVPCVBodyType: initialState.GCVPCVBodyType,
                GCVPCVBodyList: initialState.GCVPCVBodyList,
                GCVPCVMake: initialState.GCVPCVMake,
                GCVPCVModel: initialState.GCVPCVModel,
                GCVPCVFuel: initialState.GCVPCVFuel,
                GCVPCVVeriant: initialState.GCVPCVVeriant,
                PcvJourney: initialState.PcvJourney,
                GCVPCVRto: initialState.GCVPCVRto
            }
        default:
            return state
    }
}

export default GcvPcvReducers